import { useState, useEffect, useContext } from "react";
import Spinner from "../../ui/spinner/Spinner";
import LoginContext from "../../../store/login/LoginContext";
import styles from "./OptionsStyles.module.css";
import axios from "axios";

const regex_name = /^[a-zA-Z]{2,15}$/;
const nameValidation = (value) => regex_name.test(value);

const regex_email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const capitalize_name = (value) => {
  return (
    value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  );
};

const ProfileUpdate = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const loginCtx = useContext(LoginContext);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [callname, setCallname] = useState();
  const [firstnameHasError, setFirstnameHasError] = useState();
  const [lastnameHasError, setLastnameHasError] = useState();
  const [callnameHasError, setCallnameHasError] = useState();
  
  const fetchIniialValues = async () => {
    try {
      setShowSpinner(true);
      const response = await axios.post("php/sign_in/get_user_data.php");
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        props.onCancel;
      }
      setFirstname(response.data[0]);
      setLastname(response.data[1]);
      setCallname(response.data[2]);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
      props.onCancel;
    }
  };

  useEffect(() => {
    fetchIniialValues()
  }, []);

  const enableEditInputHandler = (event) =>
    (event.target.previousElementSibling.disabled = false);

  const handleChange = async () => {
    if (!firstname || !lastname || !callname) {
      alert("Error detected !!!", "Please fill all fields correctly");
      return;
    }
    if (!nameValidation(firstname)) {
      setFirstnameHasError(true);
      return;
    } else setFirstnameHasError(false);
    if (!nameValidation(lastname)) {
      setLastnameHasError(true);
      return;
    } else setLastnameHasError(false);
    if (!nameValidation(callname)) {
      setCallnameHasError(true);
      return;
    } else setCallnameHasError(false);    
    //post to server. Check for change in data on the server
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/update_user_data.php", { firstname, lastname, callname }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == "NO-CHANGE") {
        setShowSpinner(false);
        alert(
          "There is no change in your data. Use cancel to closeout your request"
        );
        return;
      }
      if (response.data == "ok") {
        setShowSpinner(false);
        if (loginCtx.callname !== callname) loginCtx.callname = callname;
        alert("Profile update successful!!");
        props.onCompletion();
      } else {
        setShowSpinner(false);
        alert(`Error connecting!! Please retry`);
      }
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
    }
  };

  return (
    <div className={styles.classes}>
      <header>
        <h2>Update your profile</h2>
      </header>
      <div className={styles.inputView}>
        <label htmlFor="firstname">Firstname</label>
        <div>
          <input
            id="firstname"
            maxLength={15}
            required
            autoComplete="off"
            value={firstname}
            disabled
            onChange={(e) =>
              setFirstname(capitalize_name(e.target.value).trim())
            }
          />
          <button
            className={`${styles.editBtn}`}
            onClick={enableEditInputHandler}
          >
            Edit
          </button>
        </div>
      </div>
      {firstnameHasError && (
        <span className={styles.errorText}>Your firstname is invalid</span>
      )}
      <div className={styles.inputView}>
        <label htmlFor="lastname">Lastname</label>
        <div>
          <input
            id="lastname"
            maxLength={15}
            required
            autoComplete="off"
            disabled
            value={lastname}
            onChange={(e) =>
              setLastname(capitalize_name(e.target.value).trim())
            }
          />
          <button
            className={`${styles.editBtn}`}
            onClick={enableEditInputHandler}
          >
            Edit
          </button>
        </div>
        {lastnameHasError && (
          <span className={styles.errorText}>Your lastname is invalid</span>
        )}
      </div>
      <div className={styles.inputView}>
        <label htmlFor="callname">Callname</label>
        <div>
          <input
            id="callname"
            maxLength={15}
            required
            autoComplete="off"
            disabled
            value={callname}
            onChange={(e) =>
              setCallname(capitalize_name(e.target.value).trim())
            }
          />
          <button
            className={`${styles.editBtn}`}
            onClick={enableEditInputHandler}
          >
            Edit
          </button>
        </div>
        {callnameHasError && (
          <span className={styles.errorText}>Your callname is invalid</span>
        )}
      </div>
      <div className={styles.btnDiv}>
        <button className={styles.bgGreen} onClick={handleChange}>
          Submit
        </button>
        <button className={styles.bgRed} onClick={props.onCancel}>
          Cancel
        </button>
      </div>
      {showSpinner && <Spinner />}
    </div>
  );
};

export default ProfileUpdate;