import { useState } from "react";
import Spinner from "../../../ui/spinner/Spinner";
import Checkbox from "../../../ui/checkbox/Checkbox";
import styles from "../LoginStyles.module.css";
import axios from "axios";

const regex_password =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const passwordValidation = (value) =>
  regex_password.test(value.trim()) &&
  value.trim().toLowerCase().indexOf("password") < 0;

const ForgotPhonePassword = (props) => {  
  const [showSpinner, setShowSpinner] = useState();
  const [displayPhone, setDisplayPhone] = useState(true);
  const [displaySecrets, setDisplaySecrets] = useState();
  const [displayNewPassword, setDisplayNewPassword] = useState();

  const [phone, setPhone] = useState("");
  const [secretQuestion1, setSecretQuestion1] = useState("");
  const [secretQuestion2, setSecretQuestion2] = useState("");

  const [secretAnswer1, setSecretAnswer1] = useState("");
  const [secretAnswer1HasError, setSecretAnswer1HasError] = useState();
  const [secretAnswer2, setSecretAnswer2] = useState("");
  const [secretAnswer2HasError, setSecretAnswer2HasError] = useState();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordHasError, setNewPasswordHasError] = useState();
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [repeatNewPasswordHasError, setRepeatNewPasswordHasError] = useState();

  const handlePhone = async () => {
    if (!phone) {
      alert("Empty input!!! Please enter your phone number,begin with + and your country code");
      return;
    }
    //post to server
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/get_user_secrets_using_uid.php",
        { uid: phone },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert("Error connecting to server. Retry later");
        setShowSpinner(false);
        return;
      }
      if (response.data == 1) {
        setPhoneHasError(true);
        return;
      }
      setSecretQuestion1(response.data[0]);
      setSecretQuestion2(response.data[1]);
      setDisplayPhone(false);
      setDisplaySecrets(true);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
    }
  };

  const answersHandler = async () => {
    //validate answers
    const userResponse = {
      uid: phone,
      secretAnswer1: secretAnswer1.toLowerCase(),
      secretAnswer2: secretAnswer2.toLowerCase(),
    };
    console.log("User response:", userResponse);
    //post to server
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/check_secret_answers.php",
        userResponse,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert("Error connecting to server. Retry later");
        setShowSpinner(false);
        return;
      }
      if (response.data == 2) {
        setSecretAnswer1HasError(true);
        setSecretAnswer2HasError(true);
        setShowSpinner(false);
        return;
      }
      if (response.data == 3) {
        setSecretAnswer1HasError(true);
        setShowSpinner(false);
        return;
      }
      if (response.data == 4) {
        setSecretAnswer2HasError(true);
        setShowSpinner(false);
        return;
      }
      setDisplaySecrets(false);
      setDisplayNewPassword(true);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
    }
  };

  const [checked, setChecked] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);

  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const checkboxChangeHandler = () => {
    const passwordInput = document.getElementById("newPassword");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const repeatCheckboxChangeHandler = () => {
    const passwordInput = document.getElementById("repeatNewPassword");
    setRepeatChecked(!repeatChecked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const newPasswordHandler = async () => {
    if (!newPassword || !repeatNewPassword) {
      alert(
        "Error detected!!! All fields must be filled correctly. Both passwords must be same"
      );
      return;
    }
    if (!passwordValidation(newPassword)) {
      setNewPasswordHasError(true);
      return;
    } else setNewPasswordHasError(false);

    if (newPassword !== repeatNewPassword) {
      setRepeatNewPasswordHasError(true);
      return;
    } else setRepeatNewPasswordHasError(false);

    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/reset_password.php",
        { uid: phone.slice(1), password: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Password reset failed!!!\nThis may be due to internet connection. Please retry"
        );
        return;
      }
      alert(
        "Success!!!\nYour password has been reset\nYou may now sign in with the new password"
      );
      setShowSpinner(false);
      props.onCompletion();
    } catch (error) {
      setShowSpinner(false)
      alert(`Error: ${error} Please retry`);
    }
  };

  return (
    <div className={styles.classes}>
      <div className={styles.floatRight}>
        <button className={styles.bgRed} onClick={props.onCancel}>
          Cancel
        </button>
      </div>
      <header className={styles.hdrMT}>
        <h2>Recover your password</h2>
      </header>
      {displayPhone && (
        <>
          <div className={styles.inputView}>
            <label htmlFor="phone">Phone number</label>
            <input
              id="phone"
              placeholder="Enter your phone number"
              maxLength={15}
              required
              autoComplete="off"
              value={phone}
              onChange={(e) => setPhone(e.target.value.trim())}
            />
          </div>
          <button
            className={`${styles.bgGreen} ${styles.btnTBMargins}`}
            onClick={handlePhone}
          >
            Submit
          </button>
        </>
      )}
      {displaySecrets && (
        <>
          <header>
            <h2>Answer these questions</h2>
          </header>
          <div className={styles.inputView}>
            <label htmlFor="secretQuestion1">Secret question 1</label>
            <input
              id="secretQuestion1"
              value={secretQuestion1}
              autoCapitalize="none"
              disabled
            />
          </div>
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer1">Secret answer 1</label>
            <input
              id="secretAnswer1"
              placeholder="Enter your secret answer 1"
              value={secretAnswer1}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) => setSecretAnswer1(e.target.value.trim())}
            />
          </div>

          <div className={styles.inputView}>
            <label htmlFor="secretQuestion1">Secret question 2</label>
            <input
              id="secretQuestion2"
              value={secretQuestion2}
              autoCapitalize="none"
              disabled
            />
          </div>
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer2">Secret answer 2</label>
            <input
              id="secretAnswer1"
              placeholder="Enter your secret answer 2"
              value={secretAnswer2}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) => setSecretAnswer2(e.target.value.trim())}
            />
          </div>
          <button
            className={`${styles.bgGreen} ${styles.btnTBMargins}`}
            onClick={answersHandler}
          >
            Submit
          </button>
        </>
      )}
      {displayNewPassword && (
        <>
          <header>
            <h2>Reset your password</h2>
          </header>
          <ol>
            <strong>New Password:</strong>
            <br></br>
            <li>Must be between 8-15 characters Is case sensitive.</li>
            <li>It must not contain space and the word 'password'.</li>
            <li>
              Must contain at least: one uppercase, one lowercase, one number
              and one special character
            </li>
          </ol>
          <div className={styles.inputView}>
            <label htmlFor="password">New password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="newPassword"
                placeholder="Enter your New password"
                maxLength={15}
                required
                autoComplete="off"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={checked}
                onChange={checkboxChangeHandler}
              />
            </div>
            {newPasswordHasError && (
              <span className={styles.errorText}>
                Your password is invalid. Please read note above on password
                requirement
              </span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="repeatNewPassword">Repeat New password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="repeatNewPassword"
                placeholder="Repeat your New password"
                maxLength={15}
                required
                autoComplete="off"
                value={repeatNewPassword}
                onChange={(e) => setRepeatNewPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={repeatChecked}
                onChange={repeatCheckboxChangeHandler}
              />
            </div>
            {repeatNewPasswordHasError && (
              <span className={styles.errorText}>
                Your repeat password is invalid. Please read note above on
                password requirement
              </span>
            )}
          </div>
          <button
            className={`${styles.bgGreen} ${styles.btnTBMargins}`}
            onClick={newPasswordHandler}
          >
            Submit
          </button>
        </>
      )}
      {showSpinner && <Spinner />}
    </div>
  );
};

export default ForgotPhonePassword;
