import styles from "./RegisteredUserOptions.module.css";

const RegisteredUserOptions = (props) => {
  return (
    <div className={styles.classes}>
      <header>
        <h2 className={styles.header}>Registered User</h2>
      </header>
      <p className={styles.text}>Select how you want to sign in</p>
      <div className={styles.btnDiv}>
        <button
          className={styles.bgGreen}
          type="button"
          onClick={props.onEmail}
        >
          Email
        </button>
        <button
          className={styles.bgBlue}
          type="button"
          onClick={props.onUsername}
        >
          Username
        </button>
        <button
          className={styles.bgPurple}
          type="button"
          onClick={props.onPhone}
        >
          Phone
        </button>
        <button className={styles.bgRed} type="button" onClick={props.onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default RegisteredUserOptions;