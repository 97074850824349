import Modal from "../modal/Modal";
import styles from "./inactivityModal.module.css";

const InactivityModal = (props) => {
    return (
      <Modal onClose={props.onClose}>
        <div className={styles.classes}>
          <header>
            <h2>Warning!!!</h2>
          </header>
          <p>
            You have been idle for 10 minutes. For your data security, if you
            remain idle for another 5 minutes, you will be automatically logged
            out
          </p>
          <div className={styles.btnDiv}>
            <button
              className={styles.bgRed}
              type="button"
              onClick={props.onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
};

export default InactivityModal;