import { useState } from "react";
import Spinner from "../../../ui/spinner/Spinner";
import Checkbox from "../../../ui/checkbox/Checkbox";
import styles from "./OptionsStyles.module.css";
import axios from "axios";

const regex_phone = /^[+0-9]{7,15}$/;
const phoneValidation = (value) => regex_phone.test(value.trim()) && value.charAt(0) === "+";

const SigninWithPhone = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const [userData, setUserData] = useState({
    uid: "",
    password: "",
  });

  const [phoneHasError, setPhoneHasError] = useState();

  const [checked, setChecked] = useState(false);
  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const checkboxChangeHandler = () => {
    const passwordInput = document.getElementById("password");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const submitHandler = async () => {
    if (!userData.uid || !userData.password) {
      alert("Error detected", "Please fill all fields correctly");
      return;
    }
    if (!phoneValidation(userData.uid.trim())) {
      setPhoneHasError(true);
      return;
    } else setPhoneHasError(false);
    console.log("userData: ", userData);
    //post to server
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/signin.php",
        { ...userData, uid: userData.uid.slice(1) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
      } else if (response.data == 1) {
        alert("Wrong phone number. Retry");
      } else if (response.data == 2) {
        alert("Wrong password. Retry");
      } else {
        props.onLoggedIn({
          method: "phone",
          callname: response.data,
        });
      }
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
    }
  };

  return (
    <div className={styles.classes}>
      <header>
        <h2>Sign in with your Phone Number</h2>
      </header>
      <div className={styles.inputView}>
        <label htmlFor="phone">Phone Number</label>
        <input
          id="phone"
          placeholder="Enter your phone number"
          maxLength={15}
          required
          autoComplete="off"
          value={userData.uid}
          onChange={(e) =>
            setUserData({
              ...userData,
              uid: e.target.value.trim(),
            })
          }
        />
        {phoneHasError && (
          <span className={styles.errorText}>Your phone number is invalid</span>
        )}
      </div>
      <div className={styles.inputView}>
        <label htmlFor="password">Password</label>
        <div className={styles.flex}>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            maxLength={15}
            required
            autoComplete="off"
            value={userData.password}
            onChange={(e) =>
              setUserData({
                ...userData,
                password: e.target.value.trim(),
              })
            }
          />
          <Checkbox
            labelClassname={styles.checkboxLabel}
            inputClassname={styles.checkbox}
            label={"Show"}
            checked={checked}
            onChange={checkboxChangeHandler}
          />
        </div>
      </div>
      <div className={styles.btnDiv}>
        <button
          className={styles.bgGreen}
          type="button"
          onClick={submitHandler}
        >
          Submit
        </button>
        <button
          className={styles.bgBlue}
          type="button"
          onClick={props.onPhonePasswordHelpRequest}
        >
          Password help
        </button>
        <button className={styles.bgRed} onClick={props.onCancel}>
          Cancel
        </button>
      </div>
      {showSpinner && <Spinner />}
    </div>
  );
  
};

export default SigninWithPhone;
