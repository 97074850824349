import { useState, useEffect } from "react";
import NewUserOptions from "./NewUserOptions";
import SignupWithEmail from "./SignupWithEmail";
import SignupWithUsername from "./SignupWithUsername";
import SignupWithPhone from "./SignupWithPhone";

function NewUserSignUp(props) {
  const [options, setOptions] = useState(true);
  const [email, setEmail] = useState(false);
  const [username, setUsername] = useState(false);
  const [phone, setPhone] = useState(false);

  const emailHandler = () => {
    setEmail(true);
    setOptions(false);
  };

  const usernameHandler = () => {
    setUsername(true);
    setOptions(false);
  };

  const phoneHandler = () => {
    setPhone(true);
    setOptions(false);
  };

  const handleLoggedIn = (userData) => props.onLoggedIn(userData);

  const cancelHandler = () => {
    setEmail(false);
    setPhone(false);
    setUsername(false);
    setOptions(true);
  };

  return (
    <>
      {options && (
        <NewUserOptions
          onEmail={emailHandler}
          onPhone={phoneHandler}
          onUsername={usernameHandler}
          onCancel={props.onCancel}
        />
      )}
      {email && (
        <SignupWithEmail
          onCompletion={handleLoggedIn}
          onCancel={cancelHandler}
        />
      )}
      {username && (
        <SignupWithUsername
          onCompletion={handleLoggedIn}
          onCancel={cancelHandler}
        />
      )}
      {phone && (
        <SignupWithPhone
          onCompletion={handleLoggedIn}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
}

export default NewUserSignUp;
