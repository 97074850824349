import { useState } from "react";
import Spinner from "../../../ui/spinner/Spinner";
import Checkbox from "../../../ui/checkbox/Checkbox";
import styles from "./ChangeUid.module.css";
import axios from "axios";

const regex_email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailValidation = (value) =>
  regex_email.test(value.trim()) && value.trim().length < 25;

const regex_phone = /^[+0-9]{7,15}$/;
const phoneValidation = (value) =>
  regex_phone.test(value.trim()) && value.charAt(0) === "+";

const regex_username = /^[a-zA-Z0-9]{8,15}$/;
const usernameValidation = (value) => regex_username.test(value.trim());

const ChangeUid = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const [goBack, setGoBack] = useState(true);
  const [checkPassword, setCheckPassword] = useState(true);
  const [options, setOptions] = useState();
  const [change, setChange] = useState();

  const [isEmail, setIsEmail] = useState();
  const [isPhone, setIsPhone] = useState();
  const [isUsername, setIsUsername] = useState();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();
  const [emailHasError, setEmailHasError] = useState();
  const [phone, setPhone] = useState();
  const [phoneHasError, setPhoneHasError] = useState();
  const [username, setUsername] = useState();
  const [usernameHasError, setUsernameHasError] = useState();

  const [checked, setChecked] = useState(false);

  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const checkboxHandler = () => {
    const passwordInput = document.getElementById("password");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const passwordHandler = async () => {
    if (!password) {
      alert("Please enter your password");
      return;
    }
    console.log("password: ", password);
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/check_password.php",
        { password: password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == "fail") {
        setShowSpinner(false);
        alert("Invalid password!! Please enter your correct password");
        return;
      }
      if (response.data == "ok") {
        setOptions(true);
        setCheckPassword(false)
      } else {
        alert(`Error connecting: ${error} Please retry`);
      }
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error connecting!! Please retry`);
    }
  }

  const emailHandler = () => {
      setIsEmail(true);
      setIsPhone(false);
      setIsUsername(false);
      setChange(true);
      setOptions(false)
      setGoBack(false);
  }

  const phoneHandler = () => {
    setIsPhone(true);
    setIsEmail(false);
    setIsUsername(false);
    setChange(true);
    setOptions(false);
    setGoBack(false);
  };

  const usernameHandler = () => {
    setIsUsername(true);
    setIsEmail(false);
    setIsPhone(false);
    setChange(true);
    setOptions(false);
    setGoBack(false);
  };

  const cancelSelectionHandler = () => {
      setChange(false);
      setEmail()
      setPhone();
      setUsername();
      setOptions(true);
      setGoBack(true);
  };

  const submitHandler = async () => {
    let uid;
    if (!email && !phone && !username) {
      alert("Enter a new valid id of your choice (email/phone/username");
      return;
    }
    if (isEmail) {
      if (!emailValidation(email)) {
        setEmailHasError(true);
        return;
      } else {
        setEmailHasError(false);
        uid = email;
      }
    } else if (isPhone) {
      if (!phoneValidation(phone)) {
        setPhoneHasError(true);
        return;
      } else {
        setPhoneHasError(false);
        uid = phone.slice(1);
      }
    } else {
      if (!usernameValidation(username)) {
        setUsernameHasError(true);
        return;
      } else {
        setUsernameHasError(false);
        uid = username;
      }
    }
    console.log("new uid: ", uid);
    //post to server
    try {
      setShowSpinner(true);
      const response = await axios.post("php/sign_in/change_uid.php", { uid });
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert("Error connecting to server. Check your internet connection or retry later");
        return;
      }
      if (response.data == 1) {
        setShowSpinner(false);
        alert("This id is not available. Please use another id");
        return;
      }
      if (response.data == "ok") {
        setShowSpinner(false);
        alert("Your id has been update");
        props.onCompletion();
      } else {
        alert(`Error connecting!! Please retry`);
      }
    } catch (error) {
      setShowSpinner(false);
      alert(`Error: ${error} Please retry`);
    }
  }

  return (
    <div className={styles.classes}>
      {goBack && (
        <>
          <div className={styles.floatRight}>
            <button className={styles.bgRed} onClick={props.onCancel}>
              Cancel
            </button>
          </div>
          <header className={styles.header}>
            <h2>Change your user id (email/phone/username)</h2>
          </header>
        </>
      )}
      {checkPassword && (
        <>
          <p>
            For additional security, please enter below your current password
          </p>
          <div className={styles.inputView}>
            <label htmlFor="password">Current Password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                maxLength={15}
                required
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={checked}
                onChange={checkboxHandler}
              />
            </div>
          </div>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={passwordHandler}
          >
            Submit
          </button>
        </>
      )}
      {options && (
        <>
          <header>
            <h3>Select option</h3>
          </header>
          <div className={styles.btnDiv}>
            <button
              className={styles.bgGreen}
              type="button"
              onClick={emailHandler}
            >
              Email
            </button>
            <button
              className={styles.bgPurple}
              type="button"
              onClick={phoneHandler}
            >
              Phone
            </button>
            <button
              className={styles.bgBlue}
              type="button"
              onClick={usernameHandler}
            >
              Username
            </button>
          </div>
        </>
      )}
      {change && (
        <>
          <div className={styles.floatRight}>
            <button className={styles.bgRed} onClick={cancelSelectionHandler}>
              Cancel
            </button>
          </div>
          {isEmail && (
            <>
              <header className={styles.header}>
                <h3>Change your user id - Email option</h3>
              </header>
              <p>In future, password help support may be sent to this email</p>
              <div className={styles.inputView}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  placeholder="Enter your email  as your new id"
                  maxLength={24}
                  required
                  autoComplete="off"
                  type="email"
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value.trim().toLowerCase())
                  }
                />
                {emailHasError && (
                  <span className={styles.errorText}>
                    Your email is invalid
                  </span>
                )}
              </div>
            </>
          )}
          {isPhone && (
            <>
              <header className={styles.header}>
                <h3>Change your user id - Phone option</h3>
              </header>
              <ol className={styles.ol}>
                <li>
                  The format is + country code and your number. if any, omit 0
                  which precedes your number
                </li>
                <li>
                  In future, password help support may be sent to this phone
                  nunmber
                </li>
              </ol>
              <div className={styles.inputView}>
                <label htmlFor="phone">Phone Number</label>
                <input
                  id="phone"
                  placeholder="Enter your phone number  as your new id"
                  maxLength={15}
                  required
                  autoComplete="off"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.trim())}
                />
                {phoneHasError && (
                  <span className={styles.errorText}>
                    Your phone number is invalid
                  </span>
                )}
              </div>
            </>
          )}
          {isUsername && (
            <>
              <header className={styles.header}>
                <h3>Change your user id - Username option</h3>
              </header>
              <p>
                Your username must not have space, and must be between 8-15
                characters (alphanumerics)
              </p>
              <div className={styles.inputView}>
                <label htmlFor="username">Username</label>
                <input
                  id="username"
                  placeholder="Enter a username as your new id"
                  maxLength={15}
                  required
                  autoComplete="off"
                  value={username}
                  onChange={(e) =>
                    setUsername(e.target.value.trim().toLowerCase())
                  }
                />
                {usernameHasError && (
                  <span className={styles.errorText}>
                    Your username is invalid
                  </span>
                )}
              </div>
            </>
          )}
          <button
            className={styles.bgGreen}
            type="button"
            onClick={submitHandler}
          >
            Submit
          </button>
        </>
      )}
      {showSpinner && <Spinner />}
    </div>
  );    
};

export default ChangeUid;
