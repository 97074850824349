

const Header = () => {
    return (
        <header>
            <h1>Exam preparation and scored mock test</h1>
        </header>
    )
};

export default Header;