import styles from './UserOptions.module.css';

const UserOptions = (props) => {
    const guestHandler = () => {
        props.onUserOption('guest');
    };
    const registeredUserHandler = () => {
        props.onUserOption('registered-user');
    };
    const newUserHandler = () => {
        props.onUserOption('new-user');
    };
    const contactUsHandler = () => {
        props.onUserOption('contact-us');
    };
    
    return (
      <div className={styles.classes}>
        <header>
          <h2>Please select your option</h2>
        </header>
        <div className={styles.btnDiv}>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={newUserHandler}
          >
            Sign up
          </button>
          <button
            className={styles.bgBlue}
            type="button"
            onClick={registeredUserHandler}
          >
            Sign in
          </button>
          <button
            className={styles.bgBlack}
            type="button"
            onClick={guestHandler}
          >
            Guest
          </button>
          <button
            className={styles.bgPurple}
            type="button"
            onClick={contactUsHandler}
          >
            Contact us
          </button>
        </div>
      </div>
    );
};

export default UserOptions;