import { createContext } from "react";

const StudyContext = createContext({
  selectedSubject: "",
  selectedType: "",
  listOfTestPapers: "",
  selectedTestPaper: "",
  selectedTestPaperId: "",
  selectedTestLevel: "",
  progress: [],
  question: "",
  qId: "",
  flags: 0,
  testDocument: "",
  onCloseTestPaper: () => {},
  onShowSelectionsHandler: () => {},
});

export default StudyContext;

