import { useState, useContext } from "react";
import ChangePassword from "./ChangePassword";
import ChangeUid from "./changeUid/ChangeUid";
import ProfileUpdate from "./ProfileUpdate";
import SecretQuestionsAndAnswersUpdate from "./SecretQuestionsAndAnswersUpdate";
import LoginContext from "../../../store/login/LoginContext";
import ContactUs from "../../startPage/contactUs/ContactUs";
import styles from "../Styles.module.css";

const RegisteredUserPage = (props) => {
  const loginCtx = useContext(LoginContext);
  const [nav, setNav] = useState(true);
  const [profileUpdate, setProfileUpdate] = useState();
  const [changePassword, setChangePassword] = useState();
  const [changeUid, setChangeUid] = useState();
  const [securityUpdate, setSecurityUpdate] = useState();
  const [contact, setContact] = useState();

  const handleProfileUpdate = () => {
    setNav(false);
    setProfileUpdate(true);
  };

  const handleChangePassword = () => {
    setNav(false);
    setChangePassword(true);
  };

  const handleChangeUid = () => {
    setNav(false);
    setChangeUid(true);
  };

  const handleSecurityUpdate = () => {
    setNav(false);
    setSecurityUpdate(true);
  };

  const handleContact = () => {
    setNav(false);
    setContact(true);
  };

  const handleNav = () => {
    setNav(true);
    setChangePassword(false);
    setChangeUid(false);
    setSecurityUpdate(false);
    setProfileUpdate(false);
    setContact(false);
  };

  const handleLogout = () => {
    const isConfirmed = confirm("Do you really want to log out");
    if (isConfirmed) props.onLogout();
  };

  return (
    <>
      {nav && (
        <div className={styles.classes}>
          <header>
            <h2 className={styles.header}>User page</h2>
          </header>
          <p>Select option</p>
          <div className={styles.btnDiv}>
            <div className={styles.btns}>
              <button
                className={styles.bgBlue}
                type="button"
                onClick={handleProfileUpdate}
              >
                Update Profile
              </button>
              <button
                className={styles.bgPurple}
                type="button"
                onClick={handleSecurityUpdate}
              >
                Security Update
              </button>
            </div>
            <div className={styles.btns}>
              <button
                className={styles.bgDarkGreen}
                type="button"
                onClick={handleChangeUid}
              >
                Change id
              </button>
              <button
                className={styles.bgDarkPurple}
                type="button"
                onClick={handleChangePassword}
              >
                Change Password
              </button>
            </div>
            <div className={styles.btns}>
              <button
                className={styles.bgGreen}
                type="button"
                onClick={props.onStudyRoom}
              >
                Study Room
              </button>
              <button
                className={styles.bgBlack}
                type="button"
                onClick={handleContact}
              >
                Contact us
              </button>
            </div>
            <div className={styles.btns}>
              <button
                className={styles.bgRed}
                type="button"
                onClick={handleLogout}
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      )}
      {profileUpdate && (
        <ProfileUpdate onCompletion={handleNav} onCancel={handleNav} />
      )}
      {changePassword && (
        <ChangePassword onCompletion={handleNav} onCancel={handleNav} />
      )}
      {changeUid && <ChangeUid onCompletion={handleNav} onCancel={handleNav} />}
      {securityUpdate && (
        <SecretQuestionsAndAnswersUpdate
          onCompletion={handleNav}
          onCancel={handleNav}
        />
      )}
      {contact && <ContactUs onSubmit={handleNav} onCancel={handleNav} />}
    </>
  );
};

export default RegisteredUserPage;