import { useState } from "react";
import RegisteredUserOptions from "./RegisteredUserOptions";
import SigninWithEmail from "./SigninWithEmail";
import SigninWithPhone from "./SigninWithPhone";
import SigninWithUsername from "./SigninWithUsername";
//import SignInWithGoogle from '../newUser/SignUpWithGoogle';

const RegisteredUserSignIn = (props) => {
  const [options, setOptions] = useState(true);
  const [email, setEmail] = useState(false);
  const [username, setUsername] = useState(false);
  const [phone, setPhone] = useState(false);

  const emailHandler = () => {
    setEmail(true);
    setOptions(false);
  };

  const usernameHandler = () => {
    setUsername(true);
    setOptions(false);
  };

  const phoneHandler = () => {
    setPhone(true);
    setOptions(false);
  };

  const handleLoggedIn = (userData) => props.onLoggedIn(userData);

  const cancelHandler = () => {
    //setGoogleEmail(false);
    setEmail(false);
    setPhone(false);
    setUsername(false);
    setOptions(true);
  };
  /*
    const googleEmailHandler = () => {
        setGoogleEmail(true);
        setOptions(false);
    }
    */

  return (
    <>
      {options && (
        <RegisteredUserOptions
          onEmail={emailHandler}
          onPhone={phoneHandler}
          onUsername={usernameHandler}
          onCancel={props.onCancel}
        />
      )}
      {email && (
        <SigninWithEmail
          onLoggedIn={handleLoggedIn}
          onEmailPasswordHelpRequest={props.onEmailPasswordHelpRequest}
          onCancel={cancelHandler}
        />
      )}
      {username && (
        <SigninWithUsername
          onLoggedIn={handleLoggedIn}
          onUsernamePasswordHelpRequest={props.onUsernamePasswordHelpRequest}
          onCancel={cancelHandler}
        />
      )}
      {phone && (
        <SigninWithPhone
          onLoggedIn={handleLoggedIn}
          onPhonePasswordHelpRequest={props.onPhonePasswordHelpRequest}
          onCancel={cancelHandler}
        />
      )}
      {/* googleEmail && <SignInWithGoogle />*/}
    </>
  );
}

export default RegisteredUserSignIn;
