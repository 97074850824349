import { useState } from "react";
import Checkbox from "../../ui/checkbox/Checkbox";
import styles from "./OptionsStyles.module.css";
import axios from "axios";

const regex_password =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const passwordValidation = (value) =>
  regex_password.test(value.trim()) &&
  value.trim().toLowerCase().indexOf("password") < 0;

const ChangePassword = (props) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordHasError, setNewPasswordHasError] = useState(false);

  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [repeatNewPasswordHasError, setRepeatNewPasswordHasError] =
    useState(false);

  const [checked, setChecked] = useState(false);
  const [newChecked, setNewChecked] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const currentCheckboxHandler = () => {
    const passwordInput = document.getElementById("currentPassword");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };
  const newCheckboxHandler = () => {
    const passwordInput = document.getElementById("newPassword");
    setNewChecked(!newChecked);
    passwordInput.type = checkboxType(passwordInput.type);
  };
  const repeatCheckboxHandler = () => {
    const passwordInput = document.getElementById("repeatNewPassword");
    setRepeatChecked(!repeatChecked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const currentPasswordHandler = async () => {
    if (!currentPassword) {
      alert("Please enter your currect password");
      return;
    }
    console.log("current password: ", currentPassword);
    try {
      const response = await axios.post(
        "php/sign_in/check_password.php",
        { password: currentPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == "fail") {
        alert(
          "Invalid password!! Please enter your correct password"
        );
        return;
      }
      if (response.data == "ok") {
        setShowPasswordReset(true);
      } else {
        alert(`Error connecting: ${error} Please retry`);
      }
    } catch (error) {
      alert(`Error connecting!! Please retry`);
    }
  };

  const handleChange = async () => {
    if (!newPassword || !repeatNewPassword) {
      alert("Error detected!! Both fields must be filled correctly");
      return;
    }
    if (newPassword === currentPassword) {
      alert(
        "There is no change in your password!! Either create a new password or use cancel to end your request"
      );
      return;
    }
    if (!passwordValidation(newPassword)) {
      setNewPasswordHasError(true);
      return;
    } else {
      setNewPasswordHasError(false);
    }
    if (!passwordValidation(repeatNewPassword)) {
      setRepeatNewPasswordHasError(true);
      return;
    }
    if (newPassword !== repeatNewPassword) {
      setRepeatNewPasswordHasError(true);
      alert("Password mismatch. Both passwords must be same");
      return;
    } else setRepeatNewPasswordHasError();
    console.log("new password: ", newPassword);
    //post to server
    try {
      const response = await axios.post(
        "php/sign_in/change_password.php",
        { password: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      // registration ok
      if (response.data == "ok") {
        alert(
          "Your password has been update.Remember your new password and keep safe"
        );
        props.onCompletion();;
      } else {
        alert(`Error connecting!! Please retry`);
      }
    } catch (error) {
      alert(`Error: ${error} Please retry`);
    }
  }
  
  return (
    <div className={styles.classes}>
      <div className={styles.floatRight}>
        <button className={styles.bgRed} onClick={props.onCancel}>
          Cancel
        </button>
      </div>
      <header className={styles.header}>
        <h2>Change your password</h2>
      </header>
      {!showPasswordReset && (
        <>
          <p>
            For additional security, please enter below your current password
          </p>
          <div className={styles.inputView}>
            <label htmlFor="currentPassword">Current Password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="currentPassword"
                placeholder="Enter your current password"
                maxLength={15}
                required
                autoComplete="off"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={checked}
                onChange={currentCheckboxHandler}
              />
            </div>
          </div>
          <div className={styles.btnDiv}>
            <button
              className={styles.bgGreen}
              type="button"
              onClick={currentPasswordHandler}
            >
              Submit
            </button>
          </div>
        </>
      )}

      {showPasswordReset && (
        <>
          <ol className={styles.ol}>
            <strong>Reset your password</strong>
            <br></br>
            <li>Your new password must be between 8-15 characters</li>
            <li>
              Password is case sensitive and must not contain space and the word
              'password'
            </li>
            <li>
              Password must contain at least: one uppercase, one lowercase, one
              number and one special characters
            </li>
          </ol>
          <div className={styles.inputView}>
            <label htmlFor="newPassword">New Password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="newPassword"
                placeholder="Enter your new password"
                maxLength={15}
                required
                autoComplete="off"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={newChecked}
                onChange={newCheckboxHandler}
              />
              {newPasswordHasError && (
                <span className={styles.errorText}>Invalid password</span>
              )}
            </div>
            <div className={styles.inputView}>
              <label htmlFor="repeatNewPassword">Repeat new password</label>
              <div className={styles.flex}>
                <input
                  type="password"
                  id="repeatNewPassword"
                  placeholder="Repeat your new password"
                  maxLength={15}
                  required
                  autoComplete="off"
                  value={repeatNewPassword}
                  onChange={(e) => setRepeatNewPassword(e.target.value.trim())}
                />
                <Checkbox
                  labelClassname={styles.checkboxLabel}
                  inputClassname={styles.checkbox}
                  label={"Show"}
                  checked={repeatChecked}
                  onChange={repeatCheckboxHandler}
                />
              </div>
              {repeatNewPasswordHasError && (
                <span className={styles.errorText}>
                  Invalid password. Also note also that both passwords must
                  match
                </span>
              )}
            </div>
          </div>
          <div className={styles.btnDiv}>
            <button
              className={styles.bgGreen}
              type="button"
              onClick={handleChange}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChangePassword;