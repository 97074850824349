import { useState, useContext } from "react";
import Spinner from "../../ui/spinner/Spinner";
import LoginContext from "../../../store/login/LoginContext";
import Checkbox from "../../ui/checkbox/Checkbox";
import styles from "./OptionsStyles.module.css";
import axios from "axios";

const regex = /^[a-zA-Z ]{2,25}$/;
const stringValidation = (value) => (!value ? false : regex.test(value));

const SecretQuestionsAndAnswersUpdate = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const [questions, setQuestions] = useState();
  const [password, setPassword] = useState("");

  const [secretQuestion1, setSecretQuestion1] = useState();
  const [secretAnswer1, setSecretAnswer1] = useState();
  const [secretQuestion2, setSecretQuestion2] = useState();
  const [secretAnswer2, setSecretAnswer2] = useState();

  const [secretQuestion1HasError, setSecretQuestion1HasError] = useState();
  const [secretAnswer1HasError, setSecretAnswer1HasError] = useState();
  const [secretQuestion2HasError, setSecretQuestion2HasError] = useState();
  const [secretAnswer2HasError, setSecretAnswer2HasError] = useState();

  const [checked, setChecked] = useState(false);
  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const checkboxHandler = () => {
    const passwordInput = document.getElementById("password");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const enableEditInputHandler = (event) =>
    (event.target.previousElementSibling.disabled = false);

  const passwordHandler = async () => {
    if (!password) {
      alert("Please enter your currect password");
      return;
    }
    console.log("current password: ", password);
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/sign_in/get_user_secrets_using_password.php", { password: password });
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == "fail") {
        setShowSpinner(false);
        alert("Invalid password!! Please enter your correct password");
        return;
      }
      setSecretQuestion1(response.data[0]);
      setSecretQuestion2(response.data[1]);
      setQuestions(true);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert(`Error connecting!! Please retry`);
    }
  };

  const handleChange = async () => {
    if (
      !secretQuestion1 ||
      !secretAnswer1 ||
      !secretQuestion2 ||
      !secretAnswer2
    ) {
      alert("Error detected", "Empty field is not allowed");
      return;
    }
    if (!stringValidation(secretQuestion1)) {
      setSecretQuestion1HasError(true);
      return;
    } else setSecretQuestion1HasError(false);
    if (!stringValidation(secretAnswer1)) {
      setSecretAnswer1HasError(true);
      return;
    } else setSecretAnswer1HasError(false);
    if (!stringValidation(secretQuestion2)) {
      setSecretQuestion2HasError(true);
      return;
    } else setSecretQuestion2HasError(false);
    if (!stringValidation(secretAnswer2)) {
      setSecretAnswer2HasError(true);
      return;
    } else setSecretAnswer2HasError(false);
    if (
      secretQuestion1.toLowerCase() ===
      secretQuestion2.toLowerCase()
    ) {
      setSecretQuestion2HasError(true);
      return;
    } else {
      setSecretQuestion2HasError(false);
    }
     
    const newSecrets = {
      secretQuestion1,
      secretAnswer1,
      secretQuestion2,
      secretAnswer2,
    };
    console.log("newSecrets: ", newSecrets);
    //update server
    //Comparison with initial secrets cannot be made due to answer hashing
    try {
      setShowSpinner(false);
      const response = await axios.post(
        "php/sign_in/update_user_secrets.php", newSecrets);
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == "ok") {
        setShowSpinner(false);
        alert("Update successfull")
        props.onCompletion();
      }
      else {
        setShowSpinner(false);
        alert("Error connecting!! Retry later");
      }
    } catch (error) {
      setShowSpinner(false);
      alert(`An error ${error} has occurred. Please try later`);
    }
  };

  return (
    <div className={styles.classes}>
      <div className={styles.floatRight}>
        <button
          className={`${styles.button}, ${styles.bgRed}`}
          onClick={props.onCancel}
        >
          Cancel
        </button>
      </div>
      <header className={styles.header}>
        <h2>Update your secret questions and answers</h2>
      </header>
      {!questions && (
        <>
          <p>For additional security, please enter below your password</p>
          <div className={styles.inputView}>
            <label htmlFor="password">Password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                maxLength={15}
                required
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={checked}
                onChange={checkboxHandler}
              />
            </div>
          </div>
          <div className={styles.btnDiv}>
            <button
              className={`${styles.button}, ${styles.bgGreen}`}
              type="button"
              onClick={passwordHandler}
            >
              Submit
            </button>
          </div>
        </>
      )}
      {questions && (
        <>
          <ol className={styles.ol}>
            <strong>Provide new question(s) and answer(s)</strong>
            <br></br>
            <li>
              Each secret question and answer must be between 2-25 characters
            </li>
            <li>The two questions cannot be the same</li>
          </ol>
          <div className={styles.inputView}>
            <label htmlFor="secretQuestion1">Secret question 1</label>
            <div>
              <input
                id="secretQuestion1"
                maxLength={25}
                required
                autoComplete="off"
                value={secretQuestion1}
                disabled
                onChange={(e) => setSecretQuestion1(e.target.value.trim())}
              />
              <button
                className={`${styles.editBtn}`}
                onClick={enableEditInputHandler}
              >
                Edit
              </button>
            </div>
          </div>
          {secretQuestion1HasError && (
            <span className={styles.errorText}>
              Your secret question is invalid
            </span>
          )}
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer1">Secret answer 1</label>
            <div>
              <input
                id="secretAnswer1"
                maxLength={25}
                required
                autoComplete="off"
                value={secretAnswer1}
                disabled
                onChange={(e) => setSecretAnswer1(e.target.value.trim())}
              />
              <button
                className={`${styles.editBtn}`}
                onClick={enableEditInputHandler}
              >
                Edit
              </button>
            </div>
          </div>
          {secretAnswer1HasError && (
            <span className={styles.errorText}>
              Your secret answer is invalid
            </span>
          )}
          <div className={styles.inputView}>
            <label htmlFor="secretQuestion2">Secret question 2</label>
            <div>
              <input
                id="secretQuestion2"
                maxLength={25}
                required
                autoComplete="off"
                value={secretQuestion2}
                disabled
                onChange={(e) => setSecretQuestion2(e.target.value.trim())}
              />
              <button
                className={`${styles.editBtn}`}
                onClick={enableEditInputHandler}
              >
                Edit
              </button>
            </div>
          </div>
          {secretQuestion2HasError && (
            <span className={styles.errorText}>
              Your secret question is invalid. Also note both questions cannot
              be the same
            </span>
          )}
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer2">Secret answer 2</label>
            <div>
              <input
                id="secretAnswer2"
                maxLength={25}
                required
                autoComplete="off"
                value={secretAnswer2}
                disabled
                onChange={(e) => setSecretAnswer2(e.target.value.trim())}
              />
              <button
                className={`${styles.editBtn}`}
                onClick={enableEditInputHandler}
              >
                Edit
              </button>
            </div>
          </div>
          {secretAnswer2HasError && (
            <span className={styles.errorText}>
              Your secret answer is invalid
            </span>
          )}
          <div className={styles.btnDiv}>
            <button
              className={`${styles.button}, ${styles.bgGreen}`}
              type="button"
              onClick={handleChange}
            >
              Submit
            </button>
          </div>
        </>
      )}
      {showSpinner && <Spinner />}
    </div>
  );
};

export default SecretQuestionsAndAnswersUpdate;
