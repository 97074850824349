import { Suspense, lazy, useContext, useState } from "react";
import LoginContext from "../../store/login/LoginContext";
import StudyContext from "../../store/studyRoom/StudyContext";
import Selections from "./selections/Selections";
//import TestPaper from "./TestPaper";
//import PerformanceRecord from "./PerformanceRecord";
import styles from "./Styles.module.css";

const TestPaper = lazy(() => import("./testPaper/TestPaper"));
const PerformanceRecord = lazy(() => import("./performanceRecord/PerformanceRecord"));

const StudyRoom = (props) => {
    const loginCtx = useContext(LoginContext);
    const user = loginCtx.user;
    const [showNav, setShowNav] = useState(true);
    const [showSelections, setShowSelections] = useState(false);
    const [showTestPaper, setShowTestPaper] = useState(false);
    const [showPerformance, setShowPerformance] = useState(false);

    const [selectedSubject, setSelectedSubject] = useState();
    const [selectedType, setSelectedType] = useState();
    const [listOfTestPapers, setListOfTestPapers] = useState();
    const [selectedTestPaper, setSelectedTestPaper] = useState();
    const [selectedTestPaperId, setSelectedTestPaperId] = useState();
    const [selectedTestLevel, setSelectedTestLevel] = useState();
    const [testDocument, setTestDocument] = useState();

    const restartHandler = () => {
      setShowNav(true);
      setShowSelections(false);
      setShowTestPaper(false);
      setShowPerformance(false);
    };

    const showSelectionsHandler = () => {
      setShowNav(false);
      setShowSelections(true);
      setShowTestPaper(false);
      setShowPerformance(false);
    };

    const showTestPaperHandler = (
      selectedSubject, selectedType, listOfTestPapers, selectedTestPaper, selectedTestLevel) => {
      console.log("SR props data: ", {
        selectedSubject,
        selectedType,
        listOfTestPapers,
        selectedTestPaper,
      });
      setSelectedSubject(selectedSubject);
      setSelectedType(selectedType);
      setListOfTestPapers(listOfTestPapers);
      setSelectedTestPaper(selectedTestPaper);
      setSelectedTestLevel(selectedTestLevel);
      setShowSelections(false);
      setShowTestPaper(true);
      setShowPerformance(false);
    };

    const selectAnotherPaperHandler = () => {
      setShowNav(true);
      setShowSelections(false);
      setShowTestPaper(false);
      setShowPerformance(false);
    };

    const showPerformanceHandler = () => {
      setShowNav(false);
      setShowSelections(false);
      setShowTestPaper(false);
      setShowPerformance(true);
    };

    const [progress, setProgress] = useState();
    const [question, setQuestion] = useState();
    //const [qId, setQId] = useState();
    //const [userId, setUserId] = useState();

    const handleLogout = () => {
      const isConfirmed = confirm("Do you really want to log out");
      if (isConfirmed) props.onLogout();
    };

    const ctxData = {
      selectedSubject,
      selectedType,
      listOfTestPapers,
      selectedTestPaper,
      selectedTestPaperId,
      selectedTestLevel,
      progress,
      question,
      testDocument,
      onCloseTestPaper: restartHandler,
      onShowSelectionsHandler: showSelectionsHandler,
    };

    return (
      <StudyContext.Provider value={ctxData}>
        {showNav && (
          <div className={styles.classes}>
            <header>
              <h2>Study Room</h2>
              <p>{loginCtx.callname}, welcome to your study room</p>
            </header>
            <div className={styles.btnDiv}>
              <button
                className={styles.bgGreen}
                type="button"
                onClick={showSelectionsHandler}
              >
                Select test paper
              </button>
              {user !== "guest" && (
                <>
                  <button
                    className={styles.bgBlue}
                    type="button"
                    onClick={showPerformanceHandler}
                  >
                    Performance
                  </button>
                  <button
                    className={styles.bgPurple}
                    type="button"
                    onClick={props.onCloseStudyRoom}
                  >
                    Personal data update
                  </button>
                </>
              )}
              <button
                className={styles.bgRed}
                type="button"
                onClick={handleLogout}
              >
                Sign out
              </button>
            </div>
          </div>
        )}
        {showSelections && (
          <Selections
            onShowTestPaper={showTestPaperHandler}
            onCancel={restartHandler}
            onLogout={props.onLogout}
            onShowPerformance={showPerformanceHandler}
          />
        )}
        {showTestPaper && (
          <Suspense fallback={<p>loading your performance record ...</p>}>
            <TestPaper
              onSelectAnotherPaper={selectAnotherPaperHandler}
              onShowPerformance={showPerformanceHandler}
              onReset={restartHandler}
              onLogout={props.onLogout}
            />
          </Suspense>
        )}
        {showPerformance && user !== "guest" && (
          <Suspense fallback={<p>loading your performance record ...</p>}>
            <PerformanceRecord
              onSelectAnotherPaper={selectAnotherPaperHandler}
              onClose={selectAnotherPaperHandler}
            />
          </Suspense>
        )}
      </StudyContext.Provider>
    );
};

export default StudyRoom;