import { useState, useEffect, lazy, Suspense, createElement } from "react";

import Spinner from "./components/ui/spinner/Spinner";
import Header from "./components/Header";
import LoginContext from "./store/login/LoginContext";
import InactivityModal from "./components/ui/inactivityModal/InactivityModal";
import Login from "./components/login/Login";
import StudyRoom from './components/studyRoom/StudyRoom';
import UserPage from './components/userPage/UserPage';

function App() {
  const [user, setUser] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [callname, setCallname] = useState("");
  const [method, setMethod] = useState("");
  const [inactive, setInactive] = useState(false);
  const [showStudyRoom, setShowStudyRoom] = useState();

  useEffect(() => {
    document.onreadystatechange = () =>
      document.readyState !== "complete"
        ? setShowSpinner(true)
        : setShowSpinner(false);
  }, []);

  useEffect(() => {
    if (typeof Storage !== "undefined") {
      // localStorage is supported
      try {
        //console.log('I am in try & catch');
        localStorage.getItem("EXAMBRIDGE").then((response) => {
          const userData = JSON.parse(response);
          if (!userData) return;
          if (userData.isLoggedIn === "1") {
            setIsLoggedIn(true);
            //userData.callname ? setCallname(userData.callname) : setCallname();
            setCallname(userData.callname);
            if (userData.type === "guest") {
              setUser("guest");
              setShowStudyRoom(true);
            } else {
              setUser("registered-user");
            }
          } else {
            setIsLoggedIn(false);
            setCallname();
          }
        });
      } catch (err) {
        console.log("Error detected", err);
      }
    }
  }, []);
  
  const contactUsHandler = () => {
    alert("Contacting us!!!");
  };

  const saveDataInLocalStorage = async (data) => {
    if (typeof Storage !== "undefined") {
      // localStorage is supported
      try {
        localStorage.setItem(
          "EXAMBRIDGE",
          JSON.stringify({
            isLoggedIn: "1",
            type: data.user,
            callname: data.callname,
            method: data.method,
          })
        );
        //console.log('Data successfully saved');
      } catch (err) {
        console.log(err, "Failed to save data");
      }
    }
  };

  useEffect(()=> {
    let idleTime = 0;
    let warning = setInterval(alarm, 60000); // 60000 = 1 minute
    let events = ['click', 'mousemove', 'keydown', 'scroll'];
    
    events.forEach(function (event) {
      document.addEventListener(event, () => {
        idleTime = 0;
      });
    });

    function alarm() {
      if (isLoggedIn) {
        idleTime += 1;
        if (idleTime > 60) { //Use 10 minutes
          setInactive(true);
        }
        if (idleTime > 120) { //Use 15 minutes
          clearTimeout(warning);
          setInactive(false);
          logOutHandler();
        }
      }
    }
  }, [isLoggedIn]);

  const guestHandler = (callname) => {
    setCallname(callname);
    setUser("guest");
    setIsLoggedIn(true);
    saveDataInLocalStorage({ user: "guest", callname, method: "" });
    setShowStudyRoom(true);
  };

  const registeredUserHandler = (userData) => {
    setIsLoggedIn(true);
    setUser("registered-user");
    setMethod(userData.method);
    saveDataInLocalStorage({ user: "registered-user", ...userData });
    if (userData.callname) {
      setCallname(userData.callname);
      setShowStudyRoom(true);
    } else {
      alert(
        "Missing credentials!!!",
        "You did not complete your registration\nHence you do not have back up help in case you forget your password\nClick on Security and Profile updates to add security back up data and other personal data respectively"
      );
    }
  };

  const logOutHandler = async () => {
    if (typeof Storage !== "undefined") {
      // localStorage is supported
      localStorage.removeItem("EXAMBRIDGE");
    }     
    setIsLoggedIn(false);
    setUser();
    setShowStudyRoom(); //due store in UserPage
  };

  const studyRoomRequestHandler = () => setShowStudyRoom(true);

  const closeStudyRoomHandler = () => setShowStudyRoom(false);

  const ctxData = {
    isLoggedIn: isLoggedIn,
    onLogout: logOutHandler,
    user: user,
    callname: callname,
    method: method,
  };

  return (
    <>
      <LoginContext.Provider value={ctxData}>
        {showSpinner && <Spinner />}
        {inactive && <InactivityModal onClose={() => setInactive(false)} />}
        {!showSpinner && (
          <main>
            <Header />
            {(!user || !isLoggedIn) && (
              <Login
                onGuestSubmission={guestHandler}
                onContactUs={contactUsHandler}
                onLoggedIn={registeredUserHandler}
                onLogout={logOutHandler}
              />
            )}
            {user && isLoggedIn && !showStudyRoom && (
              <UserPage
                onStudyRoom={studyRoomRequestHandler}
                onLogout={logOutHandler}
              />
            )}
            {user && isLoggedIn && showStudyRoom && (
              <Suspense fallback={<p>Opening your study room ...</p>}>
                <StudyRoom
                  onCloseStudyRoom={closeStudyRoomHandler}
                  onLogout={logOutHandler}
                />
              </Suspense>
            )}
          </main>
        )}
      </LoginContext.Provider>
    </>
  );
}

export default App;
/*
Testing createElement

const [file, setFile] = useState();
const test = async () => {
  try {
    // Dynamically import the selected file
    const sub = "Chemistry";
    const typ = "Objective";
    const name = "Testing";
    //`./${sub}/${typ}/${name}`
    const dynamicImportedFile = await import("./Chemistry/Objective/Testing");

    // Check if the default export is present
    if (dynamicImportedFile && dynamicImportedFile.default) {
      console.log("Dynamic Import Result:", dynamicImportedFile);
      setFile(dynamicImportedFile.default);
    } else {
      console.error("Invalid module structure - no default export");
    }

  } catch (error) {
    console.error("Error importing component:", error);
  }
};

useEffect(() => {
  test();
}, []);

Add following to the jsx return:
{file && createElement(div, null, <>{file}</>);}

I resolved the error mentioned in my previous concern by adding arg "div" to createElement
<main>
  <Header />
  {file && createElement("div", null, file)}
</main>

*/
