import styles from './ServiceTerms.module.css';

const ServiceTerms = (props) => {
    const acceptanceHandler = () => {
        props.onAcceptTerms(true);
    };

    return (
      <div className={styles.classes}>
        <header className={styles.header}>
          <h2>Terms and conditions of service</h2>
        </header>
        <p>
          Thanks for using our products. Our products are free for public use.
          By using our products, you are agreeing to our terms. Please read them
          carefully. Our products are very diverse, so sometimes additional
          terms or product requirements may apply. Additional terms will be
          available with the relevant products, and those additional terms
          become part of your agreement with us if you use those products.
        </p>
        <header>
          <h3>Using our products</h3>
        </header>
        <p>
          Don’t misuse our products. Don’t interfere with our products. Don't
          try to access them using a method other than the interface and the
          instructions that we provide. You may use our products only as
          permitted by laws and regulations. We may suspend your account if you
          do not comply with our terms or policies or if we are investigating
          suspected misconduct.<br></br>
          You do not have right of ownership of any intellectual property
          beacuse you use our products.<br></br>
          You may not use content from our products unless you obtain permission
          from us as permitted by law.<br></br>
          Do not remove, obscure, or alter any legal notices displayed in or
          along with our products.<br></br>
          Our products may display some content that is not EXAMBRIDGE.com’s.
          This content is the sole responsibility of the entity that makes it
          available. We may or not review content to determine whether it is
          illegal or violates our policies. It is your responsibility to ensure
          such contents are good for you.<br></br>
          We may send you service announcements and other information. You may
          opt out of some of the communications.<br></br>
          Always obey traffic and safety laws. They save LIFES & PROPERTIES. Do
          not use our products on mobile devices when driving.<br></br>
        </p>
        <header>
          <h3>Your User Account</h3>
        </header>
        <p>
          You need a EXAMBRIDGE.com user account in order to use our products.
          You may create your own EXAMBRIDGE.com Account, or your EXAMBRIDGE.com
          user account may be assigned to you by an administrator of your
          school. If you are using a EXAMBRIDGE.com user account assigned to you
          by an administrator, different or additional terms may apply and your
          administrator may be able to access or disable your account. To
          protect your EXAMBRIDGE.com Account, keep your password and answers to
          your secret questions confidential. You are responsible for the
          activity that happens on or through your EXAMBRIDGE.com Account. Try
          not to re-use your EXAMBRIDGE.com Account password on third-party
          applications. If you learn of any unauthorized use of your password or
          EXAMBRIDGE.com Account, fill out our Contact Us form on our website
          immediately.
        </p>
        <header>
          <h3>All rights reserved</h3>
        </header>
        <p>
          No part of this software may be reproduced, distributed, or
          transmitted in any form or by any means, including photocopying,
          recording, or other electronic or mechanical methods, without the
          prior written permission of EXAMBRIDGE.com, except in the case of
          quotations embodied in critical reviews and certain other
          non-commercial uses permitted by copyright law.<br></br>
          For permission requests, go to our Sign in page. Fill out the Contact
          Us form.
        </p>
        <header>
          <h3>DISCLAIMER by EXAMBRIDGE.com</h3>
        </header>
        <p>
          IN NO EVENT SHALL EXAMBRIDGE.com BE LIABLE TO ANY PARTY FOR DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING
          LOST PROFITS, ARISING OUT OF THE USE OF THIS SOFTWARE AND ITS
          DOCUMENTATION, EVEN IF EXAMBRIDGE.com HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGE.<br></br>
          EXAMBRIDGE.com SPECIFICALLY DISCLAIMS ANY WARRANTIES, INCLUDING, BUT
          NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE. THE SOFTWARE AND ACCOMPANYING DOCUMENTATION,
          IF ANY, PROVIDED HEREUNDER IS PROVIDED "AS IS". EXAMBRIDGE.com HAS NO
          OBLIGATION TO PROVIDE MAINTENANCE, SUPPORT, UPDATES, ENHANCEMENTS, OR
          MODIFICATIONS.<br></br>
        </p>
        <header>
          <h3>PRIVACY STATEMENT</h3>
        </header>
        <p>
          EXAMBRIDGE.com<br></br>
          http:EXAMBRIDGE.com
        </p>
        <header>
          <h3>Policy</h3>
        </header>
        <p>
          EXAMBRIDGE.com is committed to protecting the privacy and accuracy of
          your personally identifiable information to the extent possible,
          subject to provisions of state and federal law. Other than as required
          by laws that guarantee public access to certain types of information,
          or in response to subpoenas or other legal instruments that authorize
          disclosure, personally identifiable information will not be disclosed
          without your consent.
        </p>
        <header>
          <h3>Information collected and used</h3>
        </header>
        <p>
          Categories of personally identifiable information collected via this
          web site may include: name, e-mail address, and or comments directed
          to EXAMBRIDGE.com administrator. The personally identifiable
          information requested by us is intended for communication purposes.
          <br></br>
          Web servers typically collects, at least temporarily, the following
          information: Internet Protocol (IP) address of computer being used;
          web pages requested; referring web page; browser used; date and time.
          EXAMBRIDGE.com may collect statistics identifying particular IP
          addresses from which our websites are accessed.
        </p>
        <header>
          <h3>Use of collected information</h3>
        </header>
        <p>
          EXAMBRIDGE.com may use browser-IP-address information and anonymous
          browser history to report information about site accesses and for
          profiling purposes. This information is generally used to improve Web
          presentation and utilization. EXAMBRIDGE.com also may use IP address
          information for troubleshooting purposes.
        </p>
        <header>
          <h3>Cookies</h3>
        </header>
        <p>
          Cookies are used to capture and analyze web statistics. Cookies can be
          disabled within a browser's preference or option menu.
        </p>
        <h3>Disclosure of Collected Information</h3>
        <p>
          EXAMBRIDGE.com will not disclose or share, without your consent,
          personally identifiable information, except for certain explicit
          circumstances in which disclosure may be required by law. Your
          personally identifiable information will not be distributed or sold to
          third-party organizations.
        </p>
        <header>
          <h3>Communication</h3>
        </header>
        <p>
          EXAMBRIDGE.com uses only the online contact or feedback forms to
          submit requests.
        </p>
        <header>
          <h3>Updating Personal Data</h3>
        </header>
        <p>
          You can review or modify previously provided personal information.
        </p>
        <header>
          <h3>Policy Changes</h3>
        </header>
        <p>
          This privacy policy has been created and approved by EXAMBRIDGE.com.
          Any revisions to this privacy policy will be posted at this URL. Use
          the Contact Us form on our url to submit inquiries about this policy.
        </p>
        <header>
          <h3>Links to Other Sites</h3>
        </header>
        <p>
          You may encounter links to other Web sites of organizations not
          directly affiliated with EXAMBRIDGE.com. Please be aware that
          EXAMBRIDGE.com is not responsible for the information practices of
          external organizations. We recommend you review the privacy statements
          of each external Web site that collects personal information.
        </p>
        <div className={styles.btnDiv}>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={acceptanceHandler}
          >
            Accept
          </button>
          <button
            className={styles.bgRed}
            type="button"
            onClick={() => window.location.reload()}
          >
            Reject
          </button>
        </div>
      </div>
    );

};

export default ServiceTerms;