import { useState } from "react";
import Spinner from "../../../ui/spinner/Spinner";
import styles from './GuestStyles.module.css';
import axios from "axios";

const regex_name = /^[a-zA-Z]{2,15}$/;
const nameValidation = (value) => regex_name.test(value.trim());

const capitalize_name = (value) => {
  return (
    value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  );
};
    
const Guest = (props) => {  
  const [showSpinner, setShowSpinner] = useState();
  const [callname, setCallname] = useState('');
  const [callnameHasError, setCallnameHasError] = useState(false);

  const submitHandler = async () => {
      if (!callname) {
          alert("Please enter a valid callname");
          return;
      }
      if (!nameValidation(callname)) {
          setCallnameHasError(true);
          return;
      } else setCallnameHasError(false);
      //set guest session??
      try {
          setShowSpinner(true);
          const response = await axios.post("php/sign_in/start_guest_session.php", 
              {callname,}, {
              headers: {
              'Content-Type': 'application/json',
              },        
          });
          if (response.data == callname) {
              props.onSubmit(callname);
          } else {
              alert("Error connecting you. Please retry")
          }
          setShowSpinner(false);
      } catch (error) {
          alert(`Error: ${error} Please retry`);
          setShowSpinner(false)
      }
  };

  return (
    <div className={styles.classes}>
      <header>
        <h2>Guest</h2>
      </header>
      <p>Please enter below how you would like to be called</p>        
      <div className={styles.inputView}>
        <label htmlFor="callname">Callname</label>
        <input
          id="callname"
          placeholder="Enter your callname"
          maxLength={15}
          required
          autoComplete="off"
          value={callname}
          onChange={(e) =>
            setCallname(capitalize_name(e.target.value).trim())
          }
        />
        {callnameHasError && (
          <span className={styles.errorText}>Your callname is invalid</span>
        )}
      </div>
      <div className={styles.btnDiv}>
        <button
          className={styles.bgGreen}
          type="button"
          onClick={submitHandler}
        >
          Submit
        </button>
        <button
          className={styles.bgRed}
          type="button"
          onClick={props.onCancel}
        >
          Cancel
        </button>
      </div>
      {showSpinner && <Spinner />}
    </div>
  );
};

export default Guest;