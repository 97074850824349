import styles from "./NewUserOptions.module.css";

const NewUserOptions = (props) => {
    return (
      <div className={styles.classes}>
        <header>
          <h2 style={styles.h2}>New User</h2>
        </header>
        <p style={styles.p}>Select how you want to sign up</p>
        <div className={styles.btnDiv}>
          <button
            className={styles.bgGreen}
            type="button"
            onClick={props.onEmail}
          >
            Email
          </button>
          <button
            className={styles.bgBlue}
            type="button"
            onClick={props.onUsername}
          >
            Username
          </button>
          <button
            className={styles.bgPurple}
            type="button"
            onClick={props.onPhone}
          >
            Phone number
          </button>
          <button
            className={styles.bgRed}
            type="button"
            onClick={props.onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
}

export default NewUserOptions;