import { useState } from "react";
import Spinner from "../../../ui/spinner/Spinner";
import Checkbox from "../../../ui/checkbox/Checkbox";
import styles from "./OptionsStyles.module.css";
import axios from "axios";

const regex_name = /^[a-zA-Z]{2,15}$/;
const nameValidation = (value) => regex_name.test(value.trim());

const regex_username = /^[a-zA-Z0-9]{8,15}$/;
const usernameValidation = (value) => regex_username.test(value.trim());

const regex_password =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const passwordValidation = (value) =>
  regex_password.test(value.trim()) &&
  value.trim().toLowerCase().indexOf("password") < 0;

const regex = /^[a-zA-Z ]{2,25}$/;
const stringValidation = (value) => (!value ? false : regex.test(value.trim()));

const capitalize_name = (value) => {
  return (
    value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  );
};

const SignupWithUsername = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const [passwordHelp, setPasswordHelp] = useState();
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    callname: "",
    uid: "",
    password: "",
  });

  const [firstnameHasError, setFirstnameHasError] = useState();
  const [lastnameHasError, setLastnameHasError] = useState();
  const [callnameHasError, setCallnameHasError] = useState();
  const [usernameHasError, setUsernameHasError] = useState();
  const [passwordHasError, setPasswordHasError] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();

  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPasswordHasError, setRepeatPasswordHasError] = useState();
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState();

  const [secretData, setSecretData] = useState({
    secretQuestion1: "",
    secretAnswer1: "",
    secretQuestion2: "",
    secretAnswer2: "",
  });

  const [secretQuestion1HasError, setSecretQuestion1HasError] = useState();
  const [secretAnswer1HasError, setSecretAnswer1HasError] = useState();
  const [secretQuestion2HasError, setSecretQuestion2HasError] = useState();
  const [secretAnswer2HasError, setSecretAnswer2HasError] = useState();

  const [checked, setChecked] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const checkboxType = (type) => {
    type === "password" ? (type = "text") : (type = "password");
    return type;
  };

  const checkboxChangeHandler = () => {
    const passwordInput = document.getElementById("password");
    setChecked(!checked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const repeatCheckboxChangeHandler = () => {
    const passwordInput = document.getElementById("repeatPassword");
    setRepeatChecked(!repeatChecked);
    passwordInput.type = checkboxType(passwordInput.type);
  };

  const handleUserData = async () => {
    if (
      !userData.firstname ||
      !userData.lastname ||
      !userData.callname ||
      !userData.uid ||
      !userData.password ||
      !repeatPassword
    ) {
      alert("Error detected", "Please fill all fields correctly");
      return;
    }
    if (!nameValidation(userData.firstname)) {
      setFirstnameHasError(true);
      return;
    } else setFirstnameHasError(false);
    if (!nameValidation(userData.lastname)) {
      setLastnameHasError(true);
      return;
    } else setLastnameHasError(false);
    if (!nameValidation(userData.callname)) {
      setCallnameHasError(true);
      return;
    } else setCallnameHasError(false);
    if (!usernameValidation(userData.uid)) {
      setUsernameHasError(true);
      return;
    } else setUsernameHasError(false);
    if (!passwordValidation(userData.password)) {
      setPasswordHasError(true);
      setPasswordErrorMessage(
        "Your password is invalid. Please read note above on password requirement"
      );
      return;
    } else {
      setPasswordHasError(false);
      setPasswordErrorMessage();
    }
    if (!passwordValidation(repeatPassword)) {
      setRepeatPasswordHasError(true);
      setPasswordErrorMessage(
        "Your repeat password is invalid. Please read note above on password requirement"
      );
      return;
    } else {
      setRepeatPasswordHasError(false);
      setRepeatPasswordErrorMessage();
    }
    if (userData.password !== repeatPassword) {
      alert("Password mismatch", "Both passwords must be same");
      return;
    }
    console.log("userData: ", userData);
    //post to server
    try {
      const response = await axios.post("php/sign_in/signup.php", userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Check the response
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      }
      if (response.data == 1) {
        alert(
          "Your username is not available. If you are the owner of this username, then sign in as a registered user using your username and password. Else, use another username to register."
        );
        return;
      }
      // registration ok
      if (response.data == "ok") {
        alert(
          "Congrats. You have been registered. Please remember and keep safe your username and password"
        );
        setPasswordHelp(true);
      }
    } catch (error) {
      alert(`Error: ${error} Please retry`);
    }
  };

  const submitHandler = async () => {
    if (
      !secretData.secretQuestion1 ||
      !secretData.secretAnswer1 ||
      !secretData.secretQuestion2 ||
      !secretData.secretAnswer2
    ) {
      alert("Error detected", "Please fill all fields");
      return;
    }
    if (!stringValidation(secretData.secretQuestion1)) {
      setSecretQuestion1HasError(true);
      return;
    } else setSecretQuestion1HasError(false);
    if (!stringValidation(secretData.secretAnswer1)) {
      setSecretAnswer1HasError(true);
      return;
    } else setSecretAnswer1HasError(false);
    if (!stringValidation(secretData.secretQuestion2)) {
      setSecretQuestion2HasError(true);
      return;
    } else setSecretQuestion2HasError(false);
    if (!stringValidation(secretData.secretAnswer2)) {
      setSecretAnswer2HasError(true);
      return;
    } else setSecretAnswer2HasError(false);
    if (
      secretData.secretQuestion1.toLowerCase() ===
      secretData.secretQuestion2.toLowerCase()
    ) {
      setSecretQuestion2HasError(true);
      return;
    } else setSecretQuestion2HasError(false);
    //good to update server
    try {
      // Use Axios to make a POST request
      const response = await axios.post(
        "php/sign_in/update_password_help.php",
        {
          ...secretData,
          secretAnswer1: secretData.secretAnswer1.toLowerCase(),
          secretAnswer2: secretData.secretAnswer2.toLowerCase(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Check the response
      console.log("response data: ", response.data);
      if (
        response.data == "Access denied" ||
        response.data == 0 ||
        response.data == "Empty field(s)"
      ) {
        alert("Error connecting to server. Retry");
        return;
      }
      // server update ok
      if (response.data == "ok") {
        console.log("Registration completed successfully");
        props.onCompletion({
          method: "username",
          callname: capitalize_name(userData.callname),
        });
      }
    } catch (error) {
      alert(`Error: ${error} Please retry`);
    }
  };

  return (
    <div className={styles.classes}>
      <header>
        <h2>Sign up with username</h2>
      </header>
      <p>
        <button
          className={`${styles.bgRed} ${styles.floatRight}`}
          type="button"
          onClick={props.onCancel}
        >
          Cancel
        </button>
      </p>
      {!passwordHelp && (
        <div>
          <ol className={styles.ol}>
            <strong>Username:</strong>
            <br></br>
            <li>Usernane is unique & case insensitive</li>
            <li>
              The username must not have space, and must be between 8-15
              characters (alphanumerics)
            </li>
          </ol>
          <ol>
            <strong>Name:</strong>
            <br></br>
            <li>Must be between 2-15 characters all alphabets</li>
          </ol>
          <ol>
            <strong>Password:</strong>
            <br></br>
            <li>Must be between 8-15 characters Is case sensitive.</li>
            <li>It must not contain space and the word 'password'.</li>
            <li>
              Must contain at least: one uppercase, one lowercase, one number
              and one special character
            </li>
          </ol>
          <div className={styles.inputView}>
            <label htmlFor="firstname">Firstname</label>
            <input
              id="firstname"
              placeholder="Enter your firstname"
              maxLength={15}
              required
              autoComplete="off"
              value={userData.firstname}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  firstname: capitalize_name(e.target.value).trim(),
                })
              }
            />
            {firstnameHasError && (
              <span className={styles.errorText}>
                Your firstname is invalid
              </span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="lastname">Lastname</label>
            <input
              id="lastname"
              placeholder="Enter your lastname"
              maxLength={15}
              required
              autoComplete="off"
              value={userData.lastname}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  lastname: capitalize_name(e.target.value).trim(),
                })
              }
            />
            {lastnameHasError && (
              <span className={styles.errorText}>Your lastname is invalid</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="callname">Callname</label>
            <input
              id="callname"
              placeholder="Enter your callname"
              maxLength={15}
              required
              autoComplete="off"
              value={userData.callname}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  callname: capitalize_name(e.target.value).trim(),
                })
              }
            />
            {callnameHasError && (
              <span className={styles.errorText}>Your callname is invalid</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="email">Username</label>
            <input
              id="username"
              placeholder="Enter your username"
              maxLength={15}
              required
              autoComplete="off"
              value={userData.uid}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  uid: e.target.value.trim().toLowerCase(),
                })
              }
            />
            {usernameHasError && (
              <span className={styles.errorText}>Your username is invalid</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="password">Password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                maxLength={15}
                required
                autoComplete="off"
                value={userData.password}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    password: e.target.value.trim(),
                  })
                }
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={checked}
                onChange={checkboxChangeHandler}
              />
            </div>
            {passwordHasError && (
              <span className={styles.errorText}>{passwordErrorMessage}</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="repeatPassword">Repeat password</label>
            <div className={styles.flex}>
              <input
                type="password"
                id="repeatPassword"
                placeholder="Repeat your password"
                maxLength={15}
                required
                autoComplete="off"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value.trim())}
              />
              <Checkbox
                labelClassname={styles.checkboxLabel}
                inputClassname={styles.checkbox}
                label={"Show"}
                checked={repeatChecked}
                onChange={repeatCheckboxChangeHandler}
              />
            </div>
            {repeatPasswordHasError && (
              <span className={styles.errorText}>
                {repeatPasswordErrorMessage}
              </span>
            )}
          </div>
          <button
            className={`${styles.bgGreen} ${styles.btnTBMargins}`}
            onClick={handleUserData}
          >
            Register
          </button>
        </div>
      )}
      {passwordHelp && (
        <div>
          <header>
            <h2>Password help</h2>
          </header>
          <ol>
            <li>
              In case you forget your password, we can help you recover it
            </li>
            <li>
              Please provide below 3 questions we will ask you and their answers
            </li>
            <li>
              Each secret question and answer must be between 2-25 characters
            </li>
            <li>The two questions must not be the same</li>
          </ol>
          <div className={styles.inputView}>
            <label htmlFor="secretQuestion1">Secret question 1</label>
            <input
              id="secretQuestion1"
              placeholder="Enter your secret question 1"
              value={secretData.secretQuestion1}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) =>
                setSecretData({
                  ...secretData,
                  secretQuestion1: e.target.value.trim(),
                })
              }
            />
            {secretQuestion1HasError && (
              <span className={styles.errorText}>Question is invalid</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer1">Secret answer 1</label>
            <input
              id="secretAnswer1"
              placeholder="Enter your secret answer 1"
              value={secretData.secretAnswer1}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) =>
                setSecretData({
                  ...secretData,
                  secretAnswer1: e.target.value.trim(),
                })
              }
            />
            {secretAnswer1HasError && (
              <span className={styles.errorText}>Answer is invalid</span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="secretQuestion2">Secret question 2</label>
            <input
              id="secretQuestion2"
              placeholder="Enter your secret question 2"
              value={secretData.secretQuestion2}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) =>
                setSecretData({
                  ...secretData,
                  secretQuestion2: e.target.value.trim(),
                })
              }
            />
            {secretQuestion2HasError && (
              <span className={styles.errorText}>
                Question is invalid. Note that both questions cannot be same
              </span>
            )}
          </div>
          <div className={styles.inputView}>
            <label htmlFor="secretAnswer2">Secret answer 2</label>
            <input
              id="secretAnswer2"
              placeholder="Enter your secret answer 2"
              value={secretData.secretAnswer2}
              autoCapitalize="none"
              autoComplete="off"
              maxLength={25}
              onChange={(e) =>
                setSecretData({
                  ...secretData,
                  secretAnswer2: e.target.value.trim(),
                })
              }
            />
            {secretAnswer2HasError && (
              <span className={styles.errorText}>Answer is invalid</span>
            )}
          </div>
          <button
            className={`${styles.bgGreen} ${styles.btnTBMargins}`}
            onClick={submitHandler}
          >
            Submit
          </button>
        </div>
      )}
      {showSpinner && <Spinner />}
    </div>
  );
};

export default SignupWithUsername;
