import { useContext } from "react";
import LoginContext from "../../store/login/LoginContext";
import RegisteredUserPage from "./registeredUser/RegisteredUserPage";

const UserPage = (props) => {
    const loginCtx = useContext(LoginContext);
    return (
        <RegisteredUserPage
            onStudyRoom={props.onStudyRoom}
            onLogout={props.onLogout}
        />
    );
};

export default UserPage;
