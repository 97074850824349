import { useState, useEffect, useContext, createElement } from "react";
import Spinner from "../../ui/spinner/Spinner";
import LoginContext from "../../../store/login/LoginContext";
import StudyContext from "../../../store/studyRoom/StudyContext";
import styles from "./Selections.module.css";
import axios from 'axios';

const Selections = (props) => {
  const [showSpinner, setShowSpinner] = useState();
  const loginCtx = useContext(LoginContext);
  const studyCtx = useContext(StudyContext);
  const [start, setStart] = useState(true);
  const [getPapers, setGetPapers] = useState();
  const [showTestLevel, setShowTestLevel] = useState();

  const [subjects, setSubjects] = useState([]);
  const [listOfSubjects, setListOfSubjects] = useState();

  const fetchSubjects = async () => {
    try {
      setShowSpinner(true);
      const response = await axios.post("php/study_room/get_subjects.php");
      console.log("response data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert("Error connecting to server. Check your internet connection or retry later");
        return;
      }
      if (response.data == 1) {
        setShowSpinner(false);
        alert("Error getting list of subjects. Retry");
        return;
      }
      setShowSpinner(false);
      setSubjects(response.data);
      setListOfSubjects(response.data.map((subject) => (
        <option>{subject}</option>
      )));
    } catch (error) {
      setShowSpinner(false);
      alert(`An error ${error} has occurred. Please try later`);
    };
  }

  useEffect(() => {
    fetchSubjects();
  }, []);

  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedType, setSelectedType] = useState();
  const [listOfTestPapers, setListOfTestPapers] = useState([]);
  const [testPapers, setTestPapers] = useState();
  const [element, setElement] = useState();
  const [showTestPapers, setshowTestPapers] = useState();
  const [selectedTestLevel, setSelectedTestLevel] = useState("");
  const [selectedTestPaper, setSelectedTestPaper] = useState()

  const levels = [
    { id: 3, level: "Allow 3 attempts per question" },
    { id: 2, level: "Allow 2 attempts per question" },
    { id: 1, level: "Allow 1 attempt per question" },
  ];
  
  const loadSelectedSubjectHandlder = async () => {
    const obj = document.getElementById("subjects");
    let subject = obj.options[obj.selectedIndex].value;
    setSelectedSubject(subject);
    const element = document.getElementById("subjectType");
    let type = element.options[element.selectedIndex].value;
    setSelectedType(type)
    console.log("subject type: ", subject, type);
    if (!subject || !type) {
      alert("Select subject and type");
      return;
    }
    const search_data = {
      subject: subject,
      type: type,
    };
    try {
      setShowSpinner(true);
      const response = await axios.post(
        "php/study_room/get_selected_subject_test_list.php", search_data,
      );
      console.log("response.data: ", response.data);
      if (response.data == "Access denied" || response.data == 0) {
        setShowSpinner(false);
        alert(
          "Error connecting to server. Check your internet connection or retry later"
        );
        return;
      } else if (response.data == "NO FILE") {
        setShowSpinner(false);
        alert(`Exam papers have not been loaded for ${subject} ${type}`);
        return;
      } else if (response.data == "EMPTY FILE") {
        setShowSpinner(false);
        alert(`${subject} ${type} is empty`);
        return;
      } else {
        setShowSpinner(false);
        setGetPapers(true);
        setListOfTestPapers(response.data);
      }
    } catch (error) {
      setShowSpinner(false);
      alert(`An error ${error} has occurred. Please try later`);
    }
  };

  useEffect(() => {
    setTestPapers(listOfTestPapers.map((paper) => <option>{paper}</option>));
    console.log(
      "test papers: ",
      listOfTestPapers.map((paper) => <option>{paper}</option>)
    );
  }, [listOfTestPapers]);

  const loadSelectedTestPaperHandlder = () => {
    const obj = document.getElementById("test_papers_list");
    const selectedPaper = obj.options[obj.selectedIndex].value;
    setSelectedTestPaper(selectedPaper)
    //const selectedTestPaper = $('#test_papers_list option:selected').text();
    console.log("selectedPaper: ", selectedPaper);
    if (!selectedPaper) {
        alert('Select test paper');
        return;
    }
    if (selectedType !== "Objective")
      props.onShowTestPaper(selectedSubject, selectedType, listOfTestPapers, selectedPaper, selectedTestLevel);
    else {
      setShowTestLevel(true);
      //setGetPapers(false);
    }    
  };

  const submitTestLevelHandlder = () => {
    const obj = document.getElementById("test_level");
    let selectedLevel = obj.options[obj.selectedIndex].value;
    setSelectedTestLevel(selectedLevel);
    //setGetPapers(false);    
  };

  useEffect(() => {
    if (selectedTestLevel) {
      //setShowTestLevel(false);  
      props.onShowTestPaper(selectedSubject, selectedType, listOfTestPapers, selectedTestPaper, selectedTestLevel);
    }
  }, [selectedTestLevel]);

  return (
    <>
      <div className={styles.classes}>
        <div className={styles.floatRight}>
          <button className={styles.bgRed} onClick={props.onCancel}>
            Cancel
          </button>
        </div>
        <header className={styles.hdrMT}>
          <h2>Search for test paper</h2>
        </header>
        <p>Select subject, type and test paper</p>
      </div>
      {start && (
        <div className={styles.classes}>
          {!listOfSubjects && (
            <div>
              <p style={{ textAlign: "center" }}>Pending!!!</p>
            </div>
          )}
          {listOfSubjects && (
            <>
              <div>
                <label htmlFor="subjects">Select subject*:</label>
                <select id="subjects">
                  <option></option>
                  {listOfSubjects}
                </select>
              </div>
              <div>
                <label htmlFor="subjectType">Select type*:</label>
                <select id="subjectType">
                  <option></option>
                  <option>Objective</option>
                  <option>Practical</option>
                  <option>Theory</option>
                </select>
              </div>
              <button
                className={`${styles.bgBlue} ${styles.btnTBMargins}`}
                type="button"
                onClick={loadSelectedSubjectHandlder}
              >
                Submit
              </button>
            </>
          )}
        </div>
      )}
      {getPapers && (
        <div className={styles.classes}>
          {listOfTestPapers.length === 0 && (
            <div>
              <p style={{ textAlign: "center" }}>No test paper found</p>
            </div>
          )}
          {listOfTestPapers.length > 0 && (
            <>
              <div>
                <label htmlFor="test_papers_list">Select test paper*:</label>
                <select id="test_papers_list">
                  <option></option>
                  {testPapers}
                </select>
              </div>
              <button
                className={`${styles.bgBlue} ${styles.btnTBMargins}`}
                type="button"
                onClick={loadSelectedTestPaperHandlder}
              >
                Submit
              </button>
            </>
          )}
        </div>
      )}
      {showTestLevel && selectedType === "Objective" && (
        <div className={styles.classes}>
          <label htmlFor="test_level">Select test level*:</label>
          <select id="test_level">
            <option value=""></option>
            <option value="3">Allow 3 attempts per question</option>
            <option value="2">Allow 2 attempts per question</option>
            <option value="1">Allow 1 attempt per question</option>
          </select>
          <button
            className={`${styles.bgBlue} ${styles.btnTBMargins}`}
            type="button"
            onClick={submitTestLevelHandlder}
          >
            Submit
          </button>
        </div>
      )}
      {showSpinner && <Spinner />}
    </>
  );
};

export default Selections;