import { createContext } from "react";

const LoginContext = createContext({
    isLoggedIn: false,
    onLogout: () => {},
    user: '',
    callname: '',
    method: '',
});

export default LoginContext;