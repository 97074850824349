import styles from './Welcome.module.css';

const Welcome = (props) => {    
    return (
      <div className={styles.classes}>
        <p>
          Free, convenient and engaging site for students to improve their
          knowledge and prepare for exams: promotion, certification, admission
          to tertiary institutions, etc<br></br>
          Enjoy unlimited access to questions, answers and explanations from
          experienced and qualified tutors. You can sign in either as registered
          member or as guest.
        </p>
        <div className={styles.btnDiv}>
          <button onClick={props.onDisplayRobotest}>
            Next
          </button>
        </div>
      </div>
    );
};

export default Welcome;