import { useState } from 'react';
import styles from './Robotest.module.css';

const generateTest = (size) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#+=%&*?';
    let testValue = '';
    for (let i=0; i<size; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        testValue += chars.substring(randomNumber,randomNumber+1);
    }
    return testValue;
}

const Robotest = (props) => {
    const [isTouched, setIsTouched] = useState(false);
    const [test, setTest] = useState(generateTest(2));
    const [answer, setAnswer] = useState('');
    const [answerIsTouched, setAnswerIsTouched] = useState(false);
    const [answerIswrong, setAnswerIsWrong] = useState()
    const [wrongAnswerMessage, setWrongAnswerMessage] = useState('');
    const [counter, setCounter] = useState(1);

    const usernameChangeHandler = () => {
        setIsTouched(true);
    };

    const usernameBlurHandler = () => {
        if (isTouched) window.location.reload();
    };

    const preventFocusHandler = (e) => {
        e.preventDefault();
        e.target.blur();
        alert("Copying is not allowed!");
        return false;
    }

    const preventPasteHandler = (e) => {
        e.preventDefault();
        e.target.blur();
        alert("Pasting is not allowed!");
        return false;
    }

    const answerChangeHandler = (event) => {
        setAnswer(event.target.value.trim());
        setAnswerIsTouched(true);
    };

    let isValid = answerIsTouched & (answer === test);
    let errorMessage = counter === 5 ? `Please click Restart to try again`:
        `Your answer is not correct. You have ${5-counter} attempts remaining`;
    
    const answerBlurHandler = () => {
        setWrongAnswerMessage('');
        if (answer === test) {
            isValid = true;
            return;
        }
        if (counter === 1) {
            setCounter(prevState => prevState + 1);
            console.log("Counter " + counter);
            setTest(generateTest(2));
            setAnswerIsTouched(true);
            setAnswerIsWrong(true);
            setWrongAnswerMessage(errorMessage);
            isValid = false;
            return;
        }
        if (counter < 5) {
            setCounter(prevState => prevState + 1);
            console.log("Counter " + counter);
            setTest(generateTest(2));
            setWrongAnswerMessage(errorMessage);
            return;
        }
        if (counter === 5) {
            console.log("Counter " + counter);
            setWrongAnswerMessage(errorMessage);
            document.getElementById("answer").disabled = true;
        }
    };

    return (
      <form className={styles.classes} onSubmit={props.onRobotestCompleted}>
        <div>
          <header>
            <h2>I am not a robot &#128512; &#128512; &#128512;</h2>
          </header>
          <div style={{ display: "none" }}>
            <label htmlFor="username">Enter your username</label>
            <input
              style={{ display: "none" }}
              type="text"
              id="username"
              onChange={usernameChangeHandler}
              onBlur={usernameBlurHandler}
            />
          </div>
          <div>
            <label htmlFor="test">Copy</label>
            <input
              type="text"
              id="test"
              value={test}
              readOnly
              onFocus={preventFocusHandler}
            />
          </div>
          <div className={`${answerIswrong && styles.invalid}`}>
            <label htmlFor="answer">Here</label>
            <input
              type="text"
              id="answer"
              value={answer}
              onChange={answerChangeHandler}
              onBlur={answerBlurHandler}
              onPaste={preventPasteHandler}
            />
            {answerIswrong && (
              <span className={styles.errorText}>{wrongAnswerMessage}</span>
            )}
          </div>
          <div className={styles.btnDiv}>
            <button
              className={styles.bgGreen}
              type={isValid ? "submit" : "button"}
              disabled={!isValid}
            >
              Submit
            </button>
            <button
              className={styles.bgRed}
              onClick={() => window.location.reload()}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
}

export default Robotest;