import { useState } from "react";

import Welcome from "../startPage/welcome/Welcome";
import UserOptions from "../startPage/userOptions/UserOptions";
import Robotest from "../startPage/robo/Robotest";
import ServiceTerms from "../startPage/serviceTerms/ServiceTerms";
import ContactUs from "../startPage/contactUs/ContactUs";
import Guest from "./users/guest/Guest";
import ForgotEmailPassword from "./users/registeredUser/ForgotEmailPassword";
import ForgotUsernamePassword from "./users/registeredUser/ForgotUsernamePassword";
import ForgotPhonePassword from "./users/registeredUser/ForgotPhonePassword";
import NewUserSignup from "./users/newUser/NewUserSignup";
import RegisteredUserSignIn from "./users/registeredUser/RegisteredUserSignIn";

const Login = (props) => {
  const [displayWelcome, setDisplayWelcome] = useState(true);
  const [displayRobotest, setDisplayRobotest] = useState();
  const [displayUserOptions, setDisplayUserOptions] = useState();
  const [displayServiceTerms, setDisplayServiceTerms] = useState();
  const [emailPasswordResetHelp, setEmailPasswordResetHelp] = useState();
  const [usernamePasswordResetHelp, setUsernamePasswordResetHelp] = useState();
  const [phonePasswordResetHelp, setPhonePasswordResetHelp] = useState();     
  const [userOption, setUserOption] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState();
  //const [admin, setAdmin] = useState();

  const displayRoboTest = () => {
      setDisplayWelcome(false);
      setDisplayRobotest(true);
  };

  const userOptionHandler = option => {
      setUserOption(option);
      setDisplayUserOptions(false);
      setDisplayServiceTerms(true);
  };

  const displayUserOptionsHandler = () => {
      setDisplayRobotest(false);
      setDisplayUserOptions(true);
  };
  
  const serviceTermsAcceptanceHandler = accept => {
      accept ? setAcceptTerms(accept) : setUserOption();
  };
  
  const guestHandler = (callname) => {
      props.onGuestSubmission(callname);
  };

  const passwordResetCompletedHandler = () => {
      setEmailPasswordResetHelp(false)
      setUsernamePasswordResetHelp(false)
      setPhonePasswordResetHelp(false)
  }

  const showUserOptionsHandler = () => {
      setDisplayRobotest(false);
      setDisplayServiceTerms(false);
      setUserOption();
      setDisplayUserOptions(true);
      setEmailPasswordResetHelp(false);
      setUsernamePasswordResetHelp(false);
      setPhonePasswordResetHelp(false);
  }

  const logoutConfirmedHandler = () => {
      setDisplayWelcome(true);
      setDisplayRobotest();
      setDisplayUserOptions();
      setUserOption();
      setDisplayServiceTerms();
      setAcceptTerms();
      setEmailPasswordResetHelp(false);
      setUsernamePasswordResetHelp(false);
      setPhonePasswordResetHelp(false);
      props.onLogout();
  };

  const restartLoginHandler = () => {
      alert(
          'Do you really want to sign out!!!', 
          'Click OK to sign out Click Cancel to stay on',
          [{
            text: 'Cancel',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          {text: 'OK', onPress: logoutConfirmedHandler},
      ]);
  };

  const handleLoggedIn = (userData) => props.onLoggedIn(userData)

  return (
    <>
      {displayWelcome && (
        <Welcome onDisplayRobotest={displayRoboTest} />
      )}
      {displayRobotest && (
        <Robotest
          onRestart={restartLoginHandler}
          onRobotestCompleted={displayUserOptionsHandler}
        />
      )}
      {displayUserOptions && (
        <UserOptions
          onUserOption={userOptionHandler}
          onUserOptionCancel={restartLoginHandler}
        />
      )}
      {userOption === "contact-us" && (
        <ContactUs
          onSubmit={showUserOptionsHandler}
          onCancel={showUserOptionsHandler}
        />
      )}
      {displayServiceTerms &&
        (userOption === "new-user" || userOption === "guest") &&
        !acceptTerms && (
          <ServiceTerms
            onAcceptTerms={serviceTermsAcceptanceHandler}
            onDecline={restartLoginHandler}
          />
        )}
      {userOption === "guest" && acceptTerms && (
        <Guest onSubmit={guestHandler} onCancel={showUserOptionsHandler} />
      )}
      {userOption === "registered-user" &&
        !emailPasswordResetHelp &&
        !usernamePasswordResetHelp &&
        !phonePasswordResetHelp && (
          <RegisteredUserSignIn
            onLoggedIn={handleLoggedIn}
            onEmailPasswordHelpRequest={() => setEmailPasswordResetHelp(true)}
            onUsernamePasswordHelpRequest={() =>
              setUsernamePasswordResetHelp(true)
            }
            onPhonePasswordHelpRequest={() => setPhonePasswordResetHelp(true)}
            onLogout={restartLoginHandler}
            onCancel={showUserOptionsHandler}
          />
        )}
      {userOption === "registered-user" && emailPasswordResetHelp && (
        <ForgotEmailPassword
          onCompletion={passwordResetCompletedHandler}
          onCancel={showUserOptionsHandler}
        />
      )}
      {userOption === "registered-user" && usernamePasswordResetHelp && (
        <ForgotUsernamePassword
          onCompletion={passwordResetCompletedHandler}
          onCancel={showUserOptionsHandler}
        />
      )}
      {userOption === "registered-user" && phonePasswordResetHelp && (
        <ForgotPhonePassword
          onCompletion={passwordResetCompletedHandler}
          onCancel={showUserOptionsHandler}
        />
      )}
      {userOption === "new-user" &&
        acceptTerms &&
        !emailPasswordResetHelp &&
        !usernamePasswordResetHelp &&
        !phonePasswordResetHelp && (
          <NewUserSignup
            onLoggedIn={handleLoggedIn}
            onCancel={showUserOptionsHandler}
          />
        )}
    </>
  );
};

export default Login;