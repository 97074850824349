import styles from './Checkbox.module.css';

const Checkbox = (props) => {
  return (
    <label className={styles.checkboxLabel}>
      <input
          type="checkbox"
          className={styles.checkbox}
          checked={props.checked} 
          onChange={props.onChange}
      />{props.label}
    </label>
  );
};
  
export default Checkbox;